#work-view {
	z-index: 11;
	background-color: $brand-white;
}

.work-nav {
	.wrap { 
		display: flex;
		justify-content: space-between; 
	}
	.work-nav-prev, .work-nav-next, .work-nav-home {
		svg {
			display: inline-block; 
			width: 30px; height: 30px;
			vertical-align: top;
		}
	}
	a { text-decoration: none; }
	a, span { display: inline-block; vertical-align: top; }
}

#work-nav {
	position: absolute;
	z-index: 1;
	left: 0; right: 0;
	line-height: 1.875rem;
	.flex-left, .flex-center, .flex-right { display: flex; }
	.flex-left { justify-content: flex-start; flex: 0 0 auto; }
	.flex-right { justify-content: flex-end; flex: 0 0 auto; }
	.flex-center { justify-content: center; flex: 1 1 auto; }
	.work-nav-indexes, .work-nav-title {
		font-size: 1rem;
		line-height: 1.875;
		height: 1.875rem;
		font-weight: $font-header-weight;
		text-transform: uppercase;
	}
	.work-nav-index, .work-nav-total, .work-nav-title { margin: 0 0.25rem; }
	.work-nav-prev, .work-nav-next { padding: 0 2.5vw; }
	.site-nav-cross { transform: rotate( -135deg ); }
	@media screen and (max-width: $screen-xs-max) {
		.work-nav-prev, .work-nav-next { padding: 0 2.5vw; }
		.flex-center { justify-content: flex-end; }
		.flex-right { padding-left: 2.5vw; }
		.work-nav-info { display: none; }
	}
	@media screen and (min-width: $screen-sm-min) {
		padding-top: $padding-wrap-sm;
		padding-bottom: $padding-wrap-sm;
		.wrap { padding-top: 0; padding-bottom: 0; }
	}
	@media screen and (min-width: $screen-sm-min) and (min-height: $screen-sm-min) {
		&.sticky { 
			position: fixed;
			padding-top: $padding-wrap-sm / 2;
			padding-bottom: $padding-wrap-sm / 2;
		}
	}
	@media screen and (min-width: $screen-lg-min) {
		padding-top: $padding-wrap-lg-v;
		padding-bottom: $padding-wrap-lg-v;
		.work-nav-info { width: 25vw; max-width: 400px; text-align: center; }
		.flex-left, .flex-right { flex-basis: 126px; }
		&.sticky {
			position: fixed;
			padding-top: $padding-wrap-lg-v / 2;
			padding-bottom: $padding-wrap-lg-v / 2;
		}
	}
}

#work-nav-bottom {
	font-size: 1.25rem;
	margin: 4rem 0;
	.work-nav-prev svg { margin-right: 0.5rem; }
	.work-nav-next svg { margin-left: 0.5rem; }
	@media screen and (min-width: $screen-sm-min) {
		.wrap { justify-content: center; }
		a { padding: 0 2vw; margin: 0 2vw; }
	}
}

.work-single {
	color: $brand-black;
	ul.meta {
		position: relative;
		opacity: 0.75;
		padding: 0;
		margin: 1rem 0;
		font-size: 0.75rem;
		line-height: 1.75;
		list-style: none;
		text-transform: uppercase;
		li {
			white-space: nowrap;
			display: inline;
			margin-right: 0.38rem;
			&::after { content: " / "; margin-left: 0.38rem; }
			&:last-child { margin-right: 0; }
			&:last-child::after { content: none; }
		}
	}
	img { width: 100%; height: auto; }
	.img-wrap {
		position: relative;
		height: 0;
		background-color: rgba(0,0,0, .05);
		.spinner {
			position: absolute;
			left: 50%; top: 50%;
			width: 100px; height: 100px;
			margin-left: -50px; margin-top: -50px;
		}
		img {
			display: block;
			position: absolute;
			top: 0; left: 0; width: 100%; height: auto;
			opacity: 0;
		}
		&.img-loaded {
			background-color: transparent;
			height: auto;
			padding-bottom: 0 !important;
			.spinner { display: none; }
			img { 
				position: static;
				opacity: 1;
				transition: 250ms opacity; 
			}
		}
	}
	.shadow {
		img, video {
			box-shadow: 0 0 2rem rgba(0,0,0,.1);
		}
	}
	.intro {
		min-height: 100vh;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		padding: 6rem 0 0 0;
		.img-wrap { background-color: transparent; }
	}
	.header {
		padding: $padding-wrap-xs;
		position: relative;
		h1 { width: 90%; margin: 0; }
		.meta, .desc { width: 90%; }
		.tag { 
			font-size: 0.75rem;
			position: absolute; 
			right: $padding-wrap-xs;
			width: 0;
			transform-origin: 0% 0%;
			transform: rotate(90deg) translate3d(0.25rem, 0.375rem, 0);
		}
		@media screen and (min-width: $screen-sm-min) { 
			padding: ($padding-wrap-sm * 2) $padding-wrap-sm $padding-wrap-sm;
			h1 { font-size: 8vmin; }
			.meta, .desc { width: 76%; }
			.tag { 
				font-size: 0.85rem; 
				right: $padding-wrap-sm;
				transform: rotate(90deg) translate3d(0.75rem, 0.425rem, 0);
			}
		}
		@media screen and (min-width: $screen-lg-min) {
			display: flex;
			align-items: flex-start;
			padding: $padding-wrap-lg-v*2 $padding-wrap-lg-h $padding-wrap-lg-v;
			h1, .meta, .desc { width: auto; }
			h1 { font-size: 4.2358rem; }
			.lead { margin-top: 0; }
			.hgroup, .desc { 
				width: 43%; 
				padding-right: $padding-wrap-lg-h;
				max-width: $screen-xxl-min * 0.38;
			}
			.tag { right: $padding-wrap-lg-h; }
		}
	}
	.wrap {
		padding-top: 0; padding-bottom: 0;
	}
	.columns {
		@media screen and (min-width: $screen-lg-min) {
			margin: 1rem 0;
			columns: auto 2;
			column-gap: 4rem;
			h1, h2, h3, h4, h5, h6, ul, ol, p, a {
				&:first-child { margin-top: 0; }
			}
		}
	}
}