body.generic-view, body.error-view {
	#site-main-inner {
		display: flex;
		flex-flow: column nowrap;
	}

	.site-view {
		display: flex;
		flex: 1 1 auto;
		flex-flow: column;
		justify-content: center;
	}

	#site-footer, #site-copyright {
		flex: 0 0 auto;
	}

	.page-intro {
		h1, h2, h3, h4, h5, h6 {
			&:first-child { margin-top: 0; }
		}
	}
}