#site-loading {
	visibility: hidden;
	display: flex; flex-flow: column nowrap; align-items: center; justify-content: center;
	position: fixed; top: 0; left: 0; right: 0; bottom: 0;
	color: #ff5a29;
	z-index: 10;
	font-size: 0.75rem;
	line-height: 2;
}
#site-loading > div { position: relative; }
#site-loading > div > span { position: absolute; top: 0; right: -0.25rem; width: 0; }
html.no-js #site-loading { display: none; }