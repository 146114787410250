// check_media
#check_media {
	visibility: hidden;
	color: $brand-orange;
	position: fixed; top: 0; left:0;
	height: 100vh;
	@media screen and (max-width: $screen-xs-max) {
		width: 0;
		&::before { content: 'xs'; }
	}
	@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		width: $screen-sm-min;
		&::before { content: 'sm'; }
	}
	@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
		width: $screen-md-min;
		&::before { content: 'md'; }
	}
	@media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
		width: $screen-lg-min;
		&::before { content: 'lg'; }
	}
	@media screen and (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
		width: $screen-xl-min;
		&::before { content: 'xl'; }
	}
	@media screen and (min-width: $screen-xxl-min) {
		width: $screen-xxl-min;
		&::before { content: 'xxl'; }
	}
}