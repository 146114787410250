html.bb-use-vh {
	body.home-view,
	body.about-view {
		#site-copyright {
			@media screen and (min-width: $screen-md-min) {
				transform: translate3d(-1rem, -7.5vh, 0) rotateZ(90deg);
			}
		}
	}

	#home-intro {
		min-height: (100vh - 7.5vh);
	}
}