* { box-sizing: border-box; }
html, body { width: 100%; height: 100%; }
body {
	font-family: $font-body-family;
	font-weight: $font-body-weight;
	line-height: $font-body-line-height;
	padding: 0; margin: 0;
}
a { color: inherit; }
strong { font-weight: $font-header-weight; }
h1, h2, h3, h4, h5, h6 { 
	font-family: $font-header-family; 
	font-weight: $font-header-weight; 
	line-height: $font-header-line-height;
	margin: 2rem 0;
	+ h1, + h2, + h3, + h4, + h5, + h6 {
		margin-top: 1rem;
	}
}
@function pow($number, $exponent, $unit) {
  $value: 1;
  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }
  @return $value + $unit;
}

h1 { font-size: pow(1.125, 7, rem); }
h2 { font-size: pow(1.125, 3, rem); }
h3 { font-size: pow(1.125, 2, rem); }
h4 { font-size: pow(1.125, 1, rem); }
h5 { font-size: 1rem; }
h6 { font-size: 0.875rem; }
p { margin: 1em 0; }
small { font-size: 75%; }
.lead { font-size: 1.125rem; }
.nowrap { white-space: nowrap; }

@media screen and (min-width: $screen-sm-min) {
	h1 { font-size: pow(1.2, 7, rem); }
	h2 { font-size: pow(1.2, 3, rem); }
	h3 { font-size: pow(1.2, 2, rem); }
	h4 { font-size: pow(1.2, 1, rem); }
	.lead { font-size: 1.2rem; }
}
@media screen and (min-width: $screen-lg-min) {
	h1 { font-size: pow(1.25, 7, rem); }
	h2 { font-size: pow(1.25, 3, rem); }
	h3 { font-size: pow(1.25, 2, rem); }
	h4 { font-size: pow(1.25, 1, rem); }
	.lead { font-size: 1.25rem; }
}

svg use { fill: currentColor; }

figure.fig {
	font-size: 0.75rem;
	text-align: center;
	margin: 0;
	img { display: block; margin: 0 auto; }
	figcaption { opacity: 0.7; margin: 0.5rem 0; }
	figcaption:first-child { margin-top: 0; }
	figcaption:last-child { margin-bottom: 0; }
	@media screen and (min-width: $screen-sm-min) {
		font-size: 0.875rem;
		figcaption { max-resolution: 1rem 0; }
	}
}

a.cta {
	text-decoration: none;
	display: inline-block;
	border-bottom: 0.125rem solid;
	transition: 150ms color;
	&:hover { color: $brand-orange; }
}