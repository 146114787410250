#home-intro {
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
}

#beansbox-statement {
	text-transform: uppercase;
	line-height: 1;
	font-size: 11.1vmin;
	margin: 0;
}
#beansbox-statement-sub {
	margin: 2vmin 0;
	font-size: 0.875rem;
	@media screen and (orientation: landscape) and (min-height: $screen-sm-min), (orientation: landscape) and (min-width: $screen-md-min) {
		br { display: none; }
	}
	@media screen and (min-height: $screen-sm-min) and (min-width: $screen-sm-min) {
		font-size: 2.4vmin;
	}
}