ul#work-listing {
	list-style: none;
	margin: 0; padding: 0;
	li {
		display: block;
		position: relative;
		margin: 6rem 0 0 0;
		z-index: 2;
	}
	a { 
		display: block;
		text-decoration: none;
	}
	.three {
		width: 2px; height: 2px;
		position: absolute;
	}
	h3 {
		position: relative;
		margin: 0; 
	}
	.tag {
		position: absolute;
		width: 0;
		color: $brand-orange;
		font-size: 0.875rem;
	}
	@media screen and (max-width: $screen-xs-max) {
		font-size: 0.875rem;
		.three { top: 38%; }
		li.featured a p { padding-right: 2.5rem;}
		li:nth-child(odd) { 
			a { padding-left: 38%; }
			.three { left: 19%; }
		}
		li:nth-child(even) {
			a p { padding-right: 38%; }
			.three { right: 24%; }
		}
		.tag {
			right: 0; top: 0;
			transform-origin: 0% 0%;
			transform: rotate(90deg) translate3d(0, 0.5rem, 0);
		}
	}
	@media screen and (min-width: $screen-sm-min) {
		display: flex;
		flex-flow: row wrap;
		li { width: 50%; margin-top: 16rem; }
		a { padding-right: $padding-wrap-sm * 2; }
		.three { top: -6rem; left: 38%; }
		h3 { display: inline-block; }
		p { max-width: 18rem; }
	}
	@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		.tag { top: 0; left: 100%; margin-left: 0.5rem; }
	}
	@media screen and (min-width: $screen-md-min) {
		.tag { top: -1.4rem; left: 0; margin-left: 0; }
	}
	@media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
		li { width: 33.33%; }
		li:nth-child(3n+2) { transform: translateY( 10rem ); }
		padding-bottom: 10rem;
	}
	@media screen and (min-width: $screen-lg-min) {
		a { padding-right: ($padding-wrap-lg-h * 2); }
	}
	@media screen and (min-width: $screen-xl-min) {
		li { width: 25%; }
		li:nth-child(2n+1) { transform: translateY( 10rem ); }
		padding-bottom: 10rem;
	}
}