#site-wrapper { 
	width: 100%; 
	height: 100vh;
}

#site-nav {
	position: absolute;
	top: 0; left: 12vw; right: 12vw; 
	// height: 0;
	color: $brand-white;
	&:target {
		position: relative;
		z-index: 2;
		left: 0; right: 0;
		height: auto;
		background-color: $brand-brown;
		nav#site-menu { opacity: inherit; }
	}
}

nav#site-menu {
	opacity: 0;
	transition: 250ms opacity;
	height: 38vh;
	text-align: center;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	font-weight: $font-header-weight;
	font-size: 4vw;
	line-height: 1;
	text-transform: uppercase;
	@media screen and (min-width: $screen-sm-min), (orientation: landscape) {
		flex-flow: row nowrap;
		height: 18vh;
	}
	a { 
		display: block;
		padding: 0.75rem 2vw;
		text-decoration: none; 
	}
}

a.site-logo svg { 
	display: block;
	width: 126px; height: 30px;
}
a.site-nav-cross { 
	display: block;
	width: 30px; height: 30px;
	transform: rotate(0deg); 
	transform-origin: 50% 50%;
	transition: 250ms transform;
	svg {
		display: block;
		width: 30px; height: 30px;
	}
}

#site-header {
	position: absolute;
	z-index: 10;
	top: 0; left: 0; right: 0;
	color: $brand-orange;
	pointer-events: none;
	.wrap { display: flex; justify-content: space-between; }
	.site-logo, .site-nav-cross { pointer-events: auto; }
	.site-logo { 
		position: relative;
		&::before { 
			content: "";
			display: block;
			position: absolute; z-index: -1;
			left: 1px; top: 1px; right: 1px; bottom: 1px;
			background-color: $brand-white; 
		}
	}
}

#site-main {
	position: relative;
	background-color: $brand-white;
	color: $brand-brown;
	transform: none;
	transition: 250ms transform;
	#site-main-inner {
		position: relative;
		min-height: 100vh;
	}
}

#bean-board {
	position: fixed;
	top: 0; left: 0; width: 100%; height: 100%;
	z-index: 1;
	pointer-events: none;
}

.site-view { position: relative; }

#site-footer, #site-copyright { visibility: hidden; }

#site-copyright {
	font-size: 0.75rem;
	color: $brand-orange;
	.wrap { padding-top: 0; }
}

.page-intro {
	&::before {
		content: "";
		display: block;
		height: 30px;
		padding: $padding-wrap-xs;
		@media screen and (min-width: $screen-sm-min) {
			padding: $padding-wrap-sm;
		}
		@media screen and (min-width: $screen-lg-min) {
			padding: $padding-wrap-lg-v $padding-wrap-lg-h;
		}
	}
}

body:not(.work-view) #site-copyright {
	@media screen and (min-width: $screen-md-min) and (min-height: $screen-sm-min) {
		.wrap { padding: 0; }
		p { margin: 0; }
		height: 1rem;
		text-align: right;
		position: fixed;
		transform-origin: 100% 50%;
		transform: translate3d(-1rem, 0, 0) rotateZ(90deg);
	}
	@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) and (min-height: $screen-sm-min) {
		right: $padding-wrap-sm;
		bottom: $padding-wrap-sm;
	}
	@media screen and (min-width: $screen-lg-min) and (min-height: $screen-sm-min) {
		right: $padding-wrap-lg-h;
		bottom: $padding-wrap-lg-v;
	}
}
body.work-view {
	#site-header a.site-nav-cross { transform: rotate(-135deg); }
}
body.home-view, body.about-view, body.work-view, body.generic-view, body.error-view {
	#site-footer, #site-copyright { visibility: inherit; }
}

body.lifted {
	position: relative;
	overflow: hidden;
	#bean-board { z-index: 99; }
}

body.modalview {
	background-color: $brand-orange;
	color: $brand-white;
	#site-wrapper {
		overflow: hidden;
		transform-style: preserve-3d;
		perspective: 100vw;
	}
	#site-main {
		overflow: hidden;
		width: 100%; height: 100vh;
	}
}

body.modalview.animate {
	#site-main {
		transform: translate3d(0, 33vh, -10vh) rotateX(2deg);
		@media screen and (min-width: $screen-sm-min), (orientation: landscape) {
			transform: translate3d(0, 10vh, -50vh) rotateX(2deg);
		}
	}
	#site-nav-toggle-overlay {
		position: fixed;
		z-index: 3;
		display: block;
		background-color: transparent;
		top: 0; left: 0; right: 0; bottom: 0;
	}
	#site-nav {
		z-index: 4;
	}
	nav#site-menu {
		opacity: 1;
	}
	a.site-nav-cross { transform: rotate(-135deg); }
}

body.switching-view {
	pointer-events: none;
	position: relative;
	overflow: hidden;
	#site-main #site-main-inner { min-height: 1000vh; } // prevent iOS address bar from showing during page change
}

html.js {
	#site-header { position: fixed; }
	#site-wrapper { visibility: hidden; }
	&.bb-quickstart, &.bb-ready, &.bb-error {
		#site-wrapper { visibility: inherit; }
	}
}