.wrap {
	position: relative;
	@media screen and (max-width: $screen-xs-max) { padding: $padding-wrap-xs; }
	@media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) { padding: $padding-wrap-sm; }
	@media screen and (min-width: $screen-lg-min) { padding: $padding-wrap-lg-v $padding-wrap-lg-h; }
	&.unwrap-top { padding-top: 0; }
	&.unwrap-left { padding-left: 0; }
	&.unwrap-right { padding-right: 0; }
	&.unwrap-bottom { padding-bottom: 0; }
}

.spacer {
	display: block;
	height: 6.86vw;
	&.narrow { height: 3.43vw; }
	&.wide   { height: 13.72vw; }
}
.gap {
	display: block;
	width: 6.86vw; height: 6.86vw;
	&.narrow { width: 3.43vw; height: 3.43vw; }
	&.wide   { width: 13.72vw; height: 13.72vw; }
}
.flex-list {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
	margin: (-6.86vw / 2);
	.flex-item {
		padding: (6.86vw / 2);
		flex: 1 1 auto;
		width: 50%;
	}
}
.full-wrap { padding: 0 0; }
.flex-wrap { padding: 0 7.14vw; }
.text-wrap { padding: 0 7.14vw; }

@media screen and (min-width: $screen-md-min) {
	.flex-list .flex-item { width: 33.33%; }
}
@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	.flex-list {
		margin: (-3.43vw / 2);
		.flex-item { padding: (3.43vw / 2); }
	}
}
@media screen and (min-width: $screen-lg-min) and (max-width: 1555px) {
	.spacer {
		height: (100vw - $padding-wrap-lg-h*2) * 6.86 / 100;
		&.narrow { height: (100vw - $padding-wrap-lg-h*2) * 3.43 / 100; }
		&.wide   { height: (100vw - $padding-wrap-lg-h*2) * 13.72 / 100; }
	}
	.gap {
		width: (100vw - $padding-wrap-lg-h*2) * 6.86 / 100; height: auto;
		&.narrow { width: (100vw - $padding-wrap-lg-h*2) * 3.43 / 100; }
		&.wide   { width: (100vw - $padding-wrap-lg-h*2) * 13.72 / 100; }
	}
	.flex-list {
		margin: (-(100vw - $padding-wrap-lg-h*2) * 3.43 / 100 / 2);
		.flex-item { padding: ((100vw - $padding-wrap-lg-h*2) * 3.43 / 100 / 2); }
	}
	.full-wrap, .flex-wrap, .text-wrap {
		margin: 0 $padding-wrap-lg-h;
	}
	.flex-wrap { padding: 0 ( (100vw - $padding-wrap-lg-h*2) * 7.14 / 100 ); }
	.text-wrap { padding: 0 ( (100vw - $padding-wrap-lg-h*2) * 7.14 / 100 * 2 ); }
}
@media screen and (min-width: 1556px) {
	.spacer {
		height: 96px;
		.narrow { height: 48px; }
		.wide   { height: 192px; }
	}
	.gap {
		width: 96px; height: auto;
		&.narrow { width: 48px; }
		&.wide   { width: 192px; }
	}
	.flex-list {
		margin: (-48px / 2);
		.flex-item { padding: (48px / 2); }
	}
	.full-wrap, .flex-wrap, .text-wrap { 
		width: 1400px; 
		margin: 0 auto;
	}
	.flex-wrap { padding: 0 100px; }
	.text-wrap { padding: 0 200px }
}

@mixin flex-row {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	&.align-center { align-items: center; }
	&.align-end { align-items: flex-end; }
	.flex-item { flex: 1 1 0; }
	.gap { flex: 0 0 auto; }
}
.flex-row-xs { @include flex-row; }
@media screen and (min-width: $screen-sm-min) { .flex-row-sm { @include flex-row; } }
@media screen and (min-width: $screen-md-min) { .flex-row-md { @include flex-row; } }
@media screen and (min-width: $screen-lg-min) { .flex-row-lg { @include flex-row; } }