#about-intro, #about-services, #about-contact {
	a { color: $brand-orange; }
	@media screen and (min-width: $screen-sm-min) {
		.wrap { padding-right: 24vw; }
	}
}
#about-intro {
	&::before { height: 24vh; }
	@media screen and (min-width: $screen-xl-min) {
		.wrap { padding-right: 38vw; }
	}
}
#about-services {
	@media screen and (min-width: $screen-xl-min) {
		.wrap { padding-right: 38vw; }
	}
}
#about-contact {
	@media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
		.wrap { padding-right: 10rem; }
	}
}

#contact-form-wrap form {
	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}
	fieldset#contact-form-server-response {
		&.success, &.failure, &.error {
			padding: 1.5rem 0;
		}
		font-size: 1.6rem;
	}
	input, textarea, button {
		font-family: $font-body-family;
		font-size: inherit;
		border-radius: 0;
		border: 0.125rem solid transparent;
		background: mix($brand-brown, $brand-white, 10%);
		padding: 0.5rem;
	}
	.input {
		width: 100%;
		margin: 1rem 0;
		font-size: 0.875rem;
		label {
			display: block;
			margin: 1rem 0 0.5rem;
		}
		input, textarea {
			display: block;
			width: 100%;
			margin: 0.5rem 0;
		}
		&.error {
			input, textarea {
				border-color: #ff0000;
				color: #ff0000;
			}
		}
	}
	.actions {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.btn {
		font-size: 1.25rem;
		background-color: $brand-orange;
		color: $brand-white;
		padding: 0.5rem 1.5rem;
		display: flex;
		align-items: center;
		svg { 
			width: 20px; height: 20px; 
			margin: 0 0 0 0.5rem;
		}
	}
	input, textarea, button {
		&:disabled {
			background-color: mix($brand-brown, $brand-white, 20%);
			color: mix($brand-brown, $brand-white, 50%);
		}
	}
	@media screen and (min-width: $screen-sm-min) {
		.input {
			display: flex;
			flex-flow: row nowrap;
			label {
				margin: 0.5rem 0 0 0;
				flex: 1 1 38%;
			}
			input, textarea {
				margin: 0;
				flex: 1 1 100%;
			}
		}
	}
	@media screen and (min-width: $screen-lg-min) {
		.input label {
			text-align: right;
			padding-right: 2rem;
		}
	}
}

html.support-input-placeholder #contact-form-wrap form {
	.input {
		font-size: 1rem;
		label { display: none; }
	}
}