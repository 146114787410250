#site-footer {
	position: relative;
	z-index: 2;
	
	.bean-shape svg {
		width: 3rem; height: auto;
		transform: scaleX(-1);
	}
	
	@media screen and (max-width: $screen-xs-max) {
		br { display: none; }
	}
	.cta + .cta { margin-left: 0.5rem; }
}
