$brand-blue: #292299;
$brand-orange: #ff5a2d;
$brand-white: #ffffff;
$brand-grey: #999999;
$brand-brown: #4C3831;
$brand-black: #131313;

$font-body-family: 'ministry', sans-serif;
$font-body-weight: 400;
$font-body-line-height: 1.4286;

$font-header-family: 'ministry', sans-serif;
$font-header-weight: 500;
$font-header-line-height: 1.1;

$padding-wrap-xs: 6.25vw;
$padding-wrap-sm: 2.375rem;
$padding-wrap-lg-v: 3.125vw;
$padding-wrap-lg-h: 5vw;

$screen-sm-min: 600px;
$screen-md-min: 900px;
$screen-lg-min: 1200px;
$screen-xl-min: 1500px;
$screen-xxl-min: 1800px;

$screen-xs-max: $screen-sm-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-md-max: $screen-lg-min - 1;
$screen-lg-max: $screen-xl-min - 1;
$screen-xl-max: $screen-xxl-min - 1;